@import "@/styles/wx_breakpoints.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.v-skeleton-loader {
  width: 100%;
  height: 100%;
  border-radius: var(--border-radius-lg);
}
.v-skeleton-loader__image {
  min-height: 300px;
  height: 100% !important;
}
